const player = function(options) {
	this.options = options
	this.create();
	this.audio.addEventListener('ended', event => {
		typeof this.options.finish == "function" && this.options.finish()
	}, false);
}

player.prototype.create = function(){
	this.audio = new Audio();
}

player.prototype.play = function(src) {
	this.audio.src = src
	this.audio.play()
}

player.prototype.finish = function() {
	this.audio.pause()
	this.create()
}

export default player
