<template>
	<div class="scroll-view" @click="onClick">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "ScrollView",
		methods: {
			onClick(){
				this.$emit("click");
			}
		}
	}
</script>

<style lang="less" scoped>
	.scroll-view {
		height: 100%;
		box-sizing: border-box;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
</style>
